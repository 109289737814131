<template>
  <!-- Two stunning locations Section -->
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-md-12 mx-auto">
        <div class="green-header-2-text text-center">
          Two stunning locations
        </div>
        <div class="row">
          <div class="col-md-6 text-center">
            <div
              class="green-header-3-text text-center"
              style="margin-top: 0px; margin-bottom: 0px"
            >
              Somerset, UK
            </div>

            <div class="row text-center py-3">
              <div class="col-md-12 mx-auto">
                <!-- Image Slider Section -->
                <ul id="lightSlider_home_uk">
                  <li
                    v-for="file in ukImages"
                    :data-thumb="file"
                    :data-src="file"
                  >
                    <img
                      width="100%"
                      :src="file"
                      alt="Amchara Health Retreats Somerset, UK"
                    />
                  </li>
                </ul>
                <div class="pb-3"></div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-9 mx-auto">
                <p>
                  Improve your health & vitality at Amchara’s rural 19th Century
                  country Manor in the stunning Somerset countryside.
                </p>
              </div>
            </div>
            <router-link
              to="/uk-retreat"
              class="btn btn-green-gradient green-link-button m-4"
              >Discover Amchara Somerset</router-link
            >
          </div>

          <div class="col-md-6 text-center">
            <div
              class="green-header-3-text text-center"
              style="margin-top: 0px; margin-bottom: 0px"
            >
              Gozo, Malta
            </div>
            <div class="row text-center py-3">
              <div class="col-md-12 mx-auto">
                <!-- Image Slider Section -->
                <ul id="lightSlider_home_malta">
                  <li
                    v-for="file in maltaImages"
                    :data-thumb="file"
                    :data-src="file"
                  >
                    <img
                      width="100%"
                      :src="file"
                      alt="Amchara Health Retreats Gozo, Malta"
                    />
                  </li>
                </ul>
                <div class="pb-3"></div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-9 mx-auto">
                <p>
                  Enjoy the ultimate detox in the sun on the historic island of
                  Gozo; Europe’s best kept secret.
                </p>
              </div>
            </div>
            <router-link
              to="/malta-retreat"
              class="btn btn-green-gradient green-link-button m-4"
              >Discover Amchara Gozo</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/lib/lightslider/js/lightslider.js";
import "@/assets/lib/lightslider/css/lightslider.css";
export default {
  data() {
    return {
      ukImages: [
        require("@/assets/images/location-slider/uk/1.jpg"),
        require("@/assets/images/location-slider/uk/2.jpg"),
        require("@/assets/images/location-slider/uk/3.jpg"),
        require("@/assets/images/location-slider/uk/4.jpg"),
        require("@/assets/images/location-slider/uk/5.jpg"),
        require("@/assets/images/location-slider/uk/6.jpg"),
        require("@/assets/images/location-slider/uk/7.jpg"),
        require("@/assets/images/location-slider/uk/8.jpg")
      ],
      maltaImages: [
        require("@/assets/images/location-slider/malta/1.jpg"),
        require("@/assets/images/location-slider/malta/2.jpg"),
        require("@/assets/images/location-slider/malta/3.jpg"),
        require("@/assets/images/location-slider/malta/4.jpg"),
        require("@/assets/images/location-slider/malta/5.jpg"),
        require("@/assets/images/location-slider/malta/6.jpg"),
        require("@/assets/images/location-slider/malta/7.jpg"),
        require("@/assets/images/location-slider/malta/8.jpg")
      ]
    };
  },
  methods: {
    fetchSliderImages() {
      // window.axios.get("/location-slider-fetch-images").then(({ data }) => {
      //   this.ukImages = data.uk;
      //   this.maltaImages = data.malta;

      // });
      this.redesignSlider("lightSlider_home_uk");
      this.redesignSlider("lightSlider_home_malta");
    },
    redesignSlider(id) {
      var slider = $("#" + id).lightSlider({
        item: 1,
        autoWidth: false,
        slideMove: 1, // slidemove will be 1 if loop is true
        slideMargin: 4,

        addClass: "",
        mode: "slide",
        useCSS: true,
        cssEasing: "ease", //'cubic-bezier(0.25, 0, 0.25, 1)',//
        easing: "linear", //'for jquery animation',////

        speed: 800, //ms'
        auto: false,
        loop: false,
        pause: 4000,

        keyPress: false,
        controls: true,
        prevHtml:
          '<i class="fas fa-chevron-circle-left fa-2x" style="color: #8bc34a;"></i>',
        nextHtml:
          '<i class="fas fa-chevron-circle-right fa-2x" style="color: #8bc34a;"></i>',

        rtl: false,
        adaptiveHeight: false,

        vertical: false,
        verticalHeight: 380,
        vThumbWidth: 100,

        thumbItem: 6,
        pager: false,
        gallery: false,
        galleryMargin: 5,
        thumbMargin: 5,
        currentPagerPosition: "middle",

        enableTouch: true,
        enableDrag: true,
        freeMove: false,
        swipeThreshold: 40,

        responsive: [
          {
            breakpoint: 800,
            settings: {
              thumbItem: 5,
              controls: true,
              gallery: false
            }
          }
        ],

        onBeforeStart: function(el) {},
        onSliderLoad: function(el) {},
        onBeforeSlide: function(el) {},
        onAfterSlide: function(el) {},
        onBeforeNextSlide: function(el) {},
        onBeforePrevSlide: function(el) {}
      });

      slider.refresh();
      //slider.play();
    }
  },
  mounted() {
    this.fetchSliderImages();
  }
};
</script>

<style>
</style>